// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import SaveIcon from '@material-ui/icons/Save';

// custom components
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    marginBottom: 20,
    minWidth: 400,
  },
}));

export default function User(props) {
  const classes = useStyles();

  const user = JSON.parse(props.user);

  const [state, setState] = React.useState({
    user,
  });

  const handleUserChange = (event) => {
    setState({
      ...state,
      user: {
        ...state.user,
        [event.target.name]: event.target.value
      },
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                アカウント情報
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box align="right">
                <form
                  id="form"
                  action={props.action}
                  method="post"
                  noValidate
                >
                  <input type="hidden" name="authenticity_token" value={props.csrfToken} />
                  <input type="hidden" name="_method" value={props.method} />
                  <input type="hidden" name="user[email]" value={state.user.email} />
                  <input type="hidden" name="user[password]" value={state.user.password} />
                  <input type="hidden" name="user[password_confirmation]" value={state.user.password_confirmation} />
                  <input type="hidden" name="user[current_password]" value={state.user.current_password} />
                  <Fab color="secondary" size="small" aria-label="save" type="submit">
                    <SaveIcon />
                  </Fab>
                </form>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ErrorMessage {...props} />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  id="email"
                  label="メールアドレス"
                  name="email"
                  onChange={(event) => handleUserChange(event)}
                  required={true}
                  type="email"
                  value={state.user.email}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  id="password"
                  label="新しいパスワード"
                  name="password"
                  onChange={(event) => handleUserChange(event)}
                  required={false}
                  type="password"
                  value={state.user.password}
                />
              </FormControl>
              <Typography variant="caption" display="block" gutterBottom>
                パスワードを変更しない場合はブランクのままにしてください
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  id="password_confirmation"
                  label="新しいパスワード（確認用）"
                  name="password_confirmation"
                  onChange={(event) => handleUserChange(event)}
                  required={false}
                  type="password"
                  value={state.user.password_confirmation}
                />
              </FormControl>
              <Typography variant="caption" display="block" gutterBottom>
                パスワードを変更しない場合はブランクのままにしてください
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  id="current_password"
                  label="現在のパスワード"
                  name="current_password"
                  onChange={(event) => handleUserChange(event)}
                  required={true}
                  type="password"
                  value={state.user.current_password}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
