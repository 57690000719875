// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function Stores(props) {
  const classes = useStyles();

  const stores = JSON.parse(props.stores);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                店舗情報一覧
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="right">
                <Fab color="primary" size="small" aria-label="add" href="/stores/new">
                  <AddIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>店舗名</TableCell>
                <TableCell>電話番号</TableCell>
                <TableCell>住所</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.map((store) => (
                <TableRow key={store.id}>
                  <TableCell><Link href={`/stores/${store.id}`}>{store.id}</Link></TableCell>
                  <TableCell>{store.name}</TableCell>
                  <TableCell>{store.tel}</TableCell>
                  <TableCell>
                    <Box>〒{store.zip}</Box>
                    <Box>{store.prefecture}</Box>
                    <Box>{store.city}</Box>
                    <Box>{store.street}</Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Container>
    </React.Fragment>
  );
}
