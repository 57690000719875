// react components
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import DateObject from "react-date-object";

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import DateUtils from '@date-io/moment';

// material-ui/icons components
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// fetch components
import fetch from 'node-fetch';

// custom components
import ErrorMessage from './ErrorMessage';

const dayOfWeek = {
  monday: '月曜日',
  tuesday: '火曜日',
  wednesday: '水曜日',
  thursday: '木曜日',
  friday: '金曜日',
  saturday: '土曜日',
  sunday: '日曜日',
  holiday: '祝日',
};

const businessTimeRange = ['from_hours', 'from_minutes', 'to_hours', 'to_minutes'];
const businessTimeTypes = {
  business_time_weekday: '平日',
  business_time_sat_sun: '土日',
  business_time_holiday: '祝日',
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    marginBottom: 20,
    minWidth: 400,
  },
  image: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
  },
  modal: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const createDateObjects = (dates) =>
  dates.map(date => new DateObject(new Date(new Date().getFullYear(), date.month - 1, date.date)));

const CalendarModal = ({ holidays, readOnly, onChange }) => {
  const minDate = new Date(new Date().getFullYear(), 1- 1, 1, 0, 0, 0);
  const maxDate = new Date(new Date().getFullYear(), 12 - 1, 31, 23, 59, 59);
  const currentDate = new Date();

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState(createDateObjects(holidays));
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const result = dates.map((date, idx) => {
      const holiday = holidays[idx] ?? {};
      holiday.month = date.month.number;
      holiday.date = date.day;
      return holiday;
    });
    onChange(result);
  }, [dates]);

  return (
    <>
      <Button color="primary" onClick={handleOpen}>カレンダー</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="calendar-modal-title"
        aria-describedby="calendar-modal-description"
      >
        <div className={classes.modal}>
          <Calendar
            value={dates}
            onChange={setDates}
            multiple
            minDate={minDate}
            maxDate={maxDate}
            currentDate={currentDate}
            readOnly={readOnly ?? false}
            plugins={[<DatePanel />]}
          />
        </div>
      </Modal>
    </>
  );
};

export default function Store(props) {
  const classes = useStyles();

  const store = JSON.parse(props.store);

  const [state, setState] = useState({ store });

  const handleStoreChange = (event) => {
    setState({
      ...state,
      store: {
        ...state.store,
        [event.target.name]: event.target.value
      },
    });
  };

  const handleRegularHolidayChange = (event) => {
    setState(prev => ({
      ...prev,
      store: {
        ...prev.store,
        store_regular_holiday: {
          ...prev.store.store_regular_holiday,
          [event.target.name]: event.target.checked
        }
      }
    }));
  };

  const handleBusinessTimeChangeList = Object.keys(businessTimeTypes)
    .reduce((acc, cur) => ({
      ...acc,
      [cur]: (event) => {
        event.persist();
        setState(prev => {
          return {
            ...prev,
            store: {
              ...prev.store,
              [cur]: {
                ...prev.store[cur],
                [event.target.name]: event.target.value
              }
            }
          };
        })
      }
    }), {});

  const handleChangeHoliday = store_holidays => {
    setState(prev => ({
      ...prev,
      store: { ...prev.store, store_holidays }
    }));
  };

  const imageRef = useRef(null);
  const freeImage1Ref = useRef(null);
  const freeImage2Ref = useRef(null);
  const freeImage3Ref = useRef(null);
  const freeImage4Ref = useRef(null);
  const freeImage5Ref = useRef(null);

  const handleStoreFileChange = (event, ref) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (() => {
      ref.current.src = fileReader.result;
    });
    fileReader.readAsDataURL(file);
    setState({
      ...state,
      store: {
        ...state.store,
        [event.target.name]: file,
      },
    });
  };

  const handleSubmit = async (event) => {
    const url = `/stores/${props.mode == 'new' ? '' : store.id}`;
    const method = props.mode == 'new' ? 'POST' : 'PUT';
    const body = new FormData();
    body.append('authenticity_token', props.csrfToken);
    body.append('store[name]', state.store.name || '');
    body.append('store[tel]', state.store.tel || '');
    body.append('store[zip]', state.store.zip || '');
    body.append('store[prefecture]', state.store.prefecture || '');
    body.append('store[city]', state.store.city || '');
    body.append('store[street]', state.store.street || '');
    body.append('store[catchphrase]', state.store.catchphrase || '');
    body.append('store[description]', state.store.description || '');
    if (state.store.image) { body.append('store[image]', state.store.image); }
    if (state.store.free_image1) { body.append('store[free_image1]', state.store.free_image1); }
    if (state.store.free_image2) { body.append('store[free_image2]', state.store.free_image2); }
    if (state.store.free_image3) { body.append('store[free_image3]', state.store.free_image3); }
    if (state.store.free_image4) { body.append('store[free_image4]', state.store.free_image4); }
    if (state.store.free_image5) { body.append('store[free_image5]', state.store.free_image5); }
    body.append('store[free_area_name]', state.store.free_area_name || '');
    body.append('store[free_item_name1]', state.store.free_item_name1 || '');
    body.append('store[free_item_name2]', state.store.free_item_name2 || '');
    body.append('store[free_item_name3]', state.store.free_item_name3 || '');
    body.append('store[free_item_name4]', state.store.free_item_name4 || '');
    body.append('store[free_item_name5]', state.store.free_item_name5 || '');
    body.append('store[free_item_value1]', state.store.free_item_value1 || '');
    body.append('store[free_item_value2]', state.store.free_item_value2 || '');
    body.append('store[free_item_value3]', state.store.free_item_value3 || '');
    body.append('store[free_item_value4]', state.store.free_item_value4 || '');
    body.append('store[free_item_value5]', state.store.free_item_value5 || '');
    body.append('store[entry_form_url]', state.store.entry_form_url || '');
    body.append('store[admin_email]', state.store.admin_email || '');
    Object.keys(dayOfWeek).forEach(key => {
      body.append(`store[store_regular_holiday_attributes][${key}]`, state.store.store_regular_holiday[key] ?? false);
    });
    Object.keys(businessTimeTypes).forEach(dayType => {
      businessTimeRange.forEach(column => {
        body.append(`store[${dayType}_attributes][${column}]`, state.store[dayType][column] ?? '');
      });
    });
    state.store.store_holidays.map((holiday, idx) => {
      body.append(`store[store_holidays_attributes][${idx}][id]`,    holiday.id ?? '');
      body.append(`store[store_holidays_attributes][${idx}][month]`, holiday.month ?? '');
      body.append(`store[store_holidays_attributes][${idx}][date]`,  holiday.date ?? '');
    });
    const response = await fetch(url, { method, body, redirect: 'manual' });
    if (response.url) {
      window.location.href = response.url;
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                店舗情報
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box align="right">
                {['show'].includes(props.mode) && (
                  <Fab color="primary" size="small" aria-label="edit" href={`/stores/${state.store.id}/edit`}>
                    <EditIcon />
                  </Fab>
                )}
                {['new', 'edit'].includes(props.mode) && (
                  <Fab color="secondary" size="small" aria-label="save" onClick={(event) => handleSubmit(event)}>
                    <SaveIcon />
                  </Fab>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ErrorMessage {...props} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="name"
                  label="店舗名"
                  name="name"
                  onChange={(event) => handleStoreChange(event)}
                  required={['new', 'edit'].includes(props.mode)}
                  type="text"
                  value={state.store.name || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="tel"
                  label="電話番号"
                  name="tel"
                  onChange={(event) => handleStoreChange(event)}
                  required={false}
                  type="text"
                  value={state.store.tel || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="zip"
                  label="郵便番号"
                  name="zip"
                  onChange={(event) => handleStoreChange(event)}
                  required={false}
                  type="text"
                  value={state.store.zip || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="prefecture"
                  label="都道府県"
                  name="prefecture"
                  onChange={(event) => handleStoreChange(event)}
                  required={false}
                  type="text"
                  value={state.store.prefecture || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="city"
                  label="市区町村"
                  name="city"
                  onChange={(event) => handleStoreChange(event)}
                  required={false}
                  type="text"
                  value={state.store.city || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="street"
                  label="住所詳細"
                  name="street"
                  onChange={(event) => handleStoreChange(event)}
                  required={false}
                  type="text"
                  value={state.store.street || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="catchphrase"
                  label="キャッチフレーズ"
                  name="catchphrase"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.catchphrase || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="description"
                  label="店舗説明"
                  multiline
                  name="description"
                  onChange={(event) => handleStoreChange(event)}
                  rows={10}
                  type="text"
                  value={state.store.description || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <FormLabel component="legend">営業時間</FormLabel>
                {Object.keys(businessTimeTypes).map(dayType => (
                  <Grid key={dayType} container alignItems="flex-end" spacing={1}>
                    <Grid item xs={12} md={1}>
                      {businessTimeTypes[dayType]}
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Box display="flex" alignItems="end">
                        <TextField
                          disabled={['show'].includes(props.mode)}
                          id={`${dayType}_from_hours`}
                          name="from_hours"
                          onChange={handleBusinessTimeChangeList[dayType]}
                          type="number"
                          min={0}
                          max={23}
                          value={state.store[dayType].from_hours ?? ''}
                        />
                        <Typography>時</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Box display="flex" alignItems="end">
                        <TextField
                          disabled={['show'].includes(props.mode)}
                          id={`${dayType}_from_hours`}
                          name="from_minutes"
                          onChange={handleBusinessTimeChangeList[dayType]}
                          type="number"
                          min={0}
                          max={59}
                          value={state.store[dayType].from_minutes ?? ''}
                        />
                        <Typography>分</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Box textAlign="center" alignItems="end">
                        <Typography>～</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Box display="flex" alignItems="end">
                        <TextField
                          disabled={['show'].includes(props.mode)}
                          id={`${dayType}_from_hours`}
                          name="to_hours"
                          onChange={handleBusinessTimeChangeList[dayType]}
                          type="number"
                          min={0}
                          max={23}
                          value={state.store[dayType].to_hours ?? ''}
                        />
                        <Typography>時</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Box display="flex" alignItems="end">
                        <TextField
                          disabled={['show'].includes(props.mode)}
                          id={`${dayType}_from_hours`}
                          name="to_minutes"
                          onChange={handleBusinessTimeChangeList[dayType]}
                          type="number"
                          min={0}
                          max={59}
                          value={state.store[dayType].to_minutes ?? ''}
                        />
                        <Typography>分</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <FormLabel component="legend">定休日</FormLabel>

                <FormGroup aria-label="regular_holiday" row>
                  {Object.keys(dayOfWeek).map(key => (
                    <FormControlLabel
                      key={key}
                      disabled={['show'].includes(props.mode)}
                      id={`regular_holiday_${key}`}
                      label={dayOfWeek[key]}
                      name={key}
                      onChange={handleRegularHolidayChange}
                      control={
                        <Checkbox
                          defaultChecked={state.store.store_regular_holiday[key] ?? false}
                        />
                      }
                      labelPlacement="bottom"
                    />
                  ))}
                </FormGroup>
                {
                  /*
                   * スーパー予約ドーンが対応していないので一時的に非活性
                    <CalendarModal
                      holidays={state.store.store_holidays}
                      readOnly={['show'].includes(props.mode)}
                      onChange={handleChangeHoliday}
                    />
                  */
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                component='fieldset'
              >
                <FormLabel component="legend">
                  店舗画像
                </FormLabel>
                <img
                  alt="店舗画像"
                  className={classes.image}
                  ref={imageRef}
                  src={state.store.image_url}
                  style={{
                    visibility: state.store.image || state.store.image_url ? 'visible' : 'hidden',
                  }}
                />
                {['new', 'edit'].includes(props.mode) && (
                  <>
                    <input
                      accept="image/*"
                      id="image"
                      name="image"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => handleStoreFileChange(event, imageRef)}
                    />
                    <label htmlFor="image">
                      <Button
                        component="span"
                        variant="outlined"
                      >
                        ファイルを選択
                      </Button>
                    </label>
                    <label>{state.store.image?.name}</label>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                component='fieldset'
              >
                <FormLabel component="legend">
                  フリー画像1
                </FormLabel>
                <img
                  alt="フリー画像1"
                  className={classes.image}
                  ref={freeImage1Ref}
                  src={state.store.free_image1_url}
                  style={{
                    visibility: state.store.free_image1 || state.store.free_image1_url ? 'visible' : 'hidden',
                  }}
                />
                {['new', 'edit'].includes(props.mode) && (
                  <>
                    <input
                      accept="image/*"
                      id="free_image1"
                      name="free_image1"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => handleStoreFileChange(event, freeImage1Ref)}
                    />
                    <label htmlFor="free_image1">
                      <Button
                        component="span"
                        variant="outlined"
                      >
                        ファイルを選択
                      </Button>
                    </label>
                    <label>{state.store.free_image1?.name}</label>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                component='fieldset'
              >
                <FormLabel component="legend">
                  フリー画像2
                </FormLabel>
                <img
                  alt="フリー画像2"
                  className={classes.image}
                  ref={freeImage2Ref}
                  src={state.store.free_image2_url}
                  style={{
                    visibility: state.store.free_image2 || state.store.free_image2_url ? 'visible' : 'hidden',
                  }}
                />
                {['new', 'edit'].includes(props.mode) && (
                  <>
                    <input
                      accept="image/*"
                      id="free_image2"
                      name="free_image2"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => handleStoreFileChange(event, freeImage2Ref)}
                    />
                    <label htmlFor="free_image2">
                      <Button
                        component="span"
                        variant="outlined"
                      >
                        ファイルを選択
                      </Button>
                    </label>
                    <label>{state.store.free_image2?.name}</label>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                component='fieldset'
              >
                <FormLabel component="legend">
                  フリー画像3
                </FormLabel>
                <img
                  alt="フリー画像3"
                  className={classes.image}
                  ref={freeImage3Ref}
                  src={state.store.free_image3_url}
                  style={{
                    visibility: state.store.free_image3 || state.store.free_image3_url ? 'visible' : 'hidden',
                  }}
                />
                {['new', 'edit'].includes(props.mode) && (
                  <>
                    <input
                      accept="image/*"
                      id="free_image3"
                      name="free_image3"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => handleStoreFileChange(event, freeImage3Ref)}
                    />
                    <label htmlFor="free_image3">
                      <Button
                        component="span"
                        variant="outlined"
                      >
                        ファイルを選択
                      </Button>
                    </label>
                    <label>{state.store.free_image3?.name}</label>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                component='fieldset'
              >
                <FormLabel component="legend">
                  フリー画像4
                </FormLabel>
                <img
                  alt="フリー画像4"
                  className={classes.image}
                  ref={freeImage4Ref}
                  src={state.store.free_image4_url}
                  style={{
                    visibility: state.store.free_image4 || state.store.free_image4_url ? 'visible' : 'hidden',
                  }}
                />
                {['new', 'edit'].includes(props.mode) && (
                  <>
                    <input
                      accept="image/*"
                      id="free_image4"
                      name="free_image4"
                      src={state.store.free_image4_url}
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => handleStoreFileChange(event, freeImage4Ref)}
                    />
                    <label htmlFor="free_image4">
                      <Button
                        component="span"
                        variant="outlined"
                      >
                        ファイルを選択
                      </Button>
                    </label>
                    <label>{state.store.free_image4?.name}</label>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                component='fieldset'
              >
                <FormLabel component="legend">
                  フリー画像5
                </FormLabel>
                <img
                  alt="フリー画像5"
                  className={classes.image}
                  ref={freeImage5Ref}
                  src={state.store.free_image5_url}
                  style={{
                    visibility: state.store.free_image5 || state.store.free_image5_url ? 'visible' : 'hidden',
                  }}
                />
                {['new', 'edit'].includes(props.mode) && (
                  <>
                    <input
                      accept="image/*"
                      id="free_image5"
                      name="free_image5"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => handleStoreFileChange(event, freeImage5Ref)}
                    />
                    <label htmlFor="free_image5">
                      <Button
                        component="span"
                        variant="outlined"
                      >
                        ファイルを選択
                      </Button>
                    </label>
                    <label>{state.store.free_image5?.name}</label>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_area_name"
                  label="フリーエリア名"
                  name="free_area_name"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.free_area_name || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_name1"
                  label="フリー項目名1"
                  name="free_item_name1"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.free_item_name1 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_value1"
                  label="フリー項目内容1"
                  multiline
                  name="free_item_value1"
                  onChange={(event) => handleStoreChange(event)}
                  rows={5}
                  type="text"
                  value={state.store.free_item_value1 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_name2"
                  label="フリー項目名2"
                  name="free_item_name2"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.free_item_name2 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_value2"
                  label="フリー項目内容2"
                  multiline
                  name="free_item_value2"
                  onChange={(event) => handleStoreChange(event)}
                  rows={5}
                  type="text"
                  value={state.store.free_item_value2 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_name3"
                  label="フリー項目名3"
                  name="free_item_name3"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.free_item_name3 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_value3"
                  label="フリー項目内容3"
                  multiline
                  name="free_item_value3"
                  onChange={(event) => handleStoreChange(event)}
                  rows={5}
                  type="text"
                  value={state.store.free_item_value3 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_name4"
                  label="フリー項目名4"
                  name="free_item_name4"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.free_item_name4 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_value4"
                  label="フリー項目内容4"
                  multiline
                  name="free_item_value4"
                  onChange={(event) => handleStoreChange(event)}
                  rows={5}
                  type="text"
                  value={state.store.free_item_value4 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_name5"
                  label="フリー項目名5"
                  name="free_item_name5"
                  onChange={(event) => handleStoreChange(event)}
                  type="text"
                  value={state.store.free_item_name5 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="free_item_value5"
                  label="フリー項目内容5"
                  multiline
                  name="free_item_value5"
                  onChange={(event) => handleStoreChange(event)}
                  rows={5}
                  type="text"
                  value={state.store.free_item_value5 || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="entry_form_url"
                  label="予約フォーム URL"
                  name="entry_form_url"
                  onChange={(event) => handleStoreChange(event)}
                  type="url"
                  value={state.store.entry_form_url || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="admin_email"
                  label="管理者メールアドレス"
                  name="admin_email"
                  onChange={(event) => handleStoreChange(event)}
                  type="url"
                  value={state.store.admin_email || ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
