// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// custom components
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    marginBottom: 20,
    minWidth: 400,
  },
}));

export default function Company(props) {
  const classes = useStyles();

  const company = JSON.parse(props.company);

  const [state, setState] = React.useState({
    company,
  });

  const handleCompanyChange = (event) => {
    setState({
      ...state,
      company: {
        ...state.company,
        [event.target.name]: event.target.value
      },
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                会社情報
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box align="right">
                {['show'].includes(props.mode) && (
                  <Fab color="primary" size="small" aria-label="edit" href={`/company/edit`}>
                    <EditIcon />
                  </Fab>
                )}
                {['edit'].includes(props.mode) && (
                  <form
                    id="form"
                    action={props.action}
                    method="post"
                    noValidate
                  >
                    <input type="hidden" name="authenticity_token" value={props.csrfToken} />
                    <input type="hidden" name="_method" value={props.method} />
                    <input type="hidden" name="company[name]" value={state.company.name || ''} />
                    <input type="hidden" name="company[tel]" value={state.company.tel || ''} />
                    <input type="hidden" name="company[zip]" value={state.company.zip || ''} />
                    <input type="hidden" name="company[prefecture]" value={state.company.prefecture || ''} />
                    <input type="hidden" name="company[city]" value={state.company.city || ''} />
                    <input type="hidden" name="company[street]" value={state.company.street || ''} />
                    <Fab color="secondary" size="small" aria-label="save" type="submit">
                      <SaveIcon />
                    </Fab>
                  </form>
              )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ErrorMessage {...props} />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="name"
                  label="会社名"
                  name="name"
                  onChange={(event) => handleCompanyChange(event)}
                  required={['edit'].includes(props.mode)}
                  type="text"
                  value={state.company.name || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="tel"
                  label="電話番号"
                  name="tel"
                  onChange={(event) => handleCompanyChange(event)}
                  required={false}
                  type="text"
                  value={state.company.tel || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="zip"
                  label="郵便番号"
                  name="zip"
                  onChange={(event) => handleCompanyChange(event)}
                  required={false}
                  type="text"
                  value={state.company.zip || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="prefecture"
                  label="都道府県"
                  name="prefecture"
                  onChange={(event) => handleCompanyChange(event)}
                  required={false}
                  type="text"
                  value={state.company.prefecture || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="city"
                  label="市区町村"
                  name="city"
                  onChange={(event) => handleCompanyChange(event)}
                  required={false}
                  type="text"
                  value={state.company.city || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="street"
                  label="住所詳細"
                  name="street"
                  onChange={(event) => handleCompanyChange(event)}
                  required={false}
                  type="text"
                  value={state.company.street || ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
