// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';

// material-ui/lab components
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function FlashMessage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const getSeverity = (messageType) => {
    switch (messageType) {
      case 'alert':
      case 'danger':
        return 'error';
      case 'warning':
        return 'warning';
      case 'notice':
      case 'info':
        return 'info';
      case 'success':
        return 'success';
      }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            onClose={handleClose}
            severity={getSeverity(props.messageType)}
            variant="filled"
          >
            {props.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </React.Fragment>
  );
}
