// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

// custom components
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box className={classes.root}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            アカウント登録
          </Typography>
          <ErrorMessage {...props} />
          <form
            action="/users"
            className={classes.form}
            method="post"
            noValidate
          >
            <input type="hidden" name="authenticity_token" value={props.csrfToken} />
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[company_name]"
                  autoFocus
                  fullWidth
                  id="user[company_name]"
                  label="会社名"
                  margin="normal"
                  name="user[company_name]"
                  required
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[store_name]"
                  fullWidth
                  id="user[store_name]"
                  label="店舗名"
                  margin="normal"
                  name="user[store_name]"
                  required
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[email]"
                  fullWidth
                  id="user[email]"
                  label="メールアドレス"
                  margin="normal"
                  name="user[email]"
                  required
                  type="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[password]"
                  fullWidth
                  id="user[password]"
                  label="パスワード"
                  margin="normal"
                  name="user[password]"
                  required
                  type="password"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[password_confirmation]"
                  fullWidth
                  id="user[password_confirmation]"
                  label="パスワード確認"
                  margin="normal"
                  name="user[password_confirmation]"
                  required
                  type="password"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              className={classes.submit}
              color="primary"
              fullWidth
              size="large"
              startIcon={<PersonAddIcon />}
              type="submit"
              variant="contained"
            >
              アカウント登録
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/users/sign_in" variant="body2">
                  既にアカウントをお持ちの方
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
}
