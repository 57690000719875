// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// material-ui/lab components
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ErrorMessage(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {(props.errorMessages && props.errorMessages.length) ? (
        <Box className={classes.root}>
          <Alert severity="error">
            {props.errorSummary}
            <ul>
              {props.errorMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
