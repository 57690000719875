// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import AddIcon from '@material-ui/icons/Add';

// moment components
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function Forms(props) {
  const classes = useStyles();

  const forms = JSON.parse(props.forms);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                フォーム一覧
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="right">
                <Fab color="primary" size="small" aria-label="add" href="/forms/new">
                  <AddIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>店舗名</TableCell>
                <TableCell>フォーム名</TableCell>
                <TableCell>店舗ページ URL</TableCell>
                <TableCell>リダイレクト URL</TableCell>
                <TableCell>更新日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forms.map((form) => (
                <TableRow key={form.id}>
                  <TableCell><Link href={`/forms/${form.id}`}>{form.id}</Link></TableCell>
                  <TableCell>{form.store.name}</TableCell>
                  <TableCell>{form.name}</TableCell>
                  <TableCell>
                    <Link href={form.store_page_url} rel="noopener" target="_blank">{form.store_page_url}</Link>
                  </TableCell>
                  <TableCell>
                    {form.redirect_url && (
                      <Link href={form.redirect_url} rel="noopener" target="_blank">{form.redirect_url}</Link>
                    )}
                  </TableCell>
                  <TableCell>{moment(form.updated_at).format('YYYY-MM-DD HH:mm')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Container>
    </React.Fragment>
  );
}
