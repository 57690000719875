// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SmsIcon from '@material-ui/icons/Sms';

// material-ui/pickers components
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// date-io components
import MomentUtils from '@date-io/moment';

// moment components
import moment from 'moment';

// fetch components
import fetch from 'node-fetch';

// custom components
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  drawer: {
    padding: 20,
    width: 400,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 400,
  },
}));

export default function Entry(props) {
  const classes = useStyles();

  const forms = JSON.parse(props.forms);
  forms.forEach((form) => {
    form.form_fields = form.form_fields.sort((a, b) => a.position - b.position);
    form.form_fields.forEach((form_field) => {
      form_field.form_field_options = form_field.form_field_options.sort((a, b) => a.position - b.position);
    });
  });
  const entry = JSON.parse(props.entry);
  const form = forms.find((form) => form.id === entry.form_id);

  const [state, setState] = React.useState({
    entry,
    result: null,
    drawer: null,
  });

  const handleEntryItemChange = (event) => {
    const entry_items = [...state.drawer.entry_items];
    const target = entry_items.find((entry_item) => entry_item.name === event.target.name);
    if (!(target)) {
      return;
    }
    target.value = event.target.value;
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        entry_items,
      },
    });
  };

  const handleEntryItemDateChange = (name, value) => {
    const entry_items = [...state.drawer.entry_items];
    const target = entry_items.find((entry_item) => entry_item.name === name);
    if (!(target)) {
      return;
    }
    target.value = value.format('YYYY-MM-DD');
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        entry_items,
      },
    });
  };

  const handleEntryItemDateTimeChange = (name, value) => {
    const entry_items = [...state.drawer.entry_items];
    const target = entry_items.find((entry_item) => entry_item.name === name);
    if (!(target)) {
      return;
    }
    target.value = value.format('YYYY-MM-DD HH:mm');
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        entry_items,
      },
    });
  };

  const handleEntryItemCheckboxChange = (event) => {
    const entry_items = [...state.drawer.entry_items];
    const target = entry_items.find((entry_item) => entry_item.name === event.target.name);
    if (!(target)) {
      return;
    }
    target.value = event.target.checked.toString();
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        entry_items,
      },
    });
  };

  const handleToggleDrawer = (event, data) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (data) {
      setState({
        ...state,
        drawer: {
          form_field: data,
          entry_items: getEntryItems(data),
        },
      });
      return;
    }
    if (state.drawer) {
      const entry_items = [...state.entry.entry_items];
      state.drawer.entry_items.forEach((entry_item) => {
        const target = entry_items.find((v) => v.form_field_id === entry_item.form_field_id && v.name === entry_item.name);
        target.value = entry_item.value;
      });
      setState({
        ...state,
        drawer: null,
        entry: {
          ...state.entry,
          entry_items,
        },
      });
    }
  };

  const getEntryItems = (form_field) => {
    return state.entry.entry_items.filter((entry_item) => entry_item.form_field_id === form_field.id);
  };

  const getEntryItemName = (form_field) => {
    const entry_items = getEntryItems(form_field);
    if (entry_items.length === 0) {
      return <>{form_field.name}</>;
    }
    if (entry_items.length === 1) {
      return <>{entry_items[0].name}</>;
    }
    return (
      <Box>
        {entry_items.map((entry_item, index) => (
          <Box key={index}>{entry_item.name}</Box>
        ))}
      </Box>
    );
  };

  const getEntryItemLabel = (form_field) => {
    const entry_items = getEntryItems(form_field);
    if (entry_items.length === 0) {
      return <>{form_field.label}</>;
    }
    if (entry_items.length === 1) {
      return <>{entry_items[0].label}</>;
    }
    return (
      <Box>
        {entry_items.map((entry_item, index) => (
          <Box key={index}>{entry_item.label}</Box>
        ))}
      </Box>
    );
  };

  const getEntryItemValue = (form_field) => {
    const entry_items = getEntryItems(form_field);
    if (entry_items.length === 0) {
      return <>-</>;
    }
    if (entry_items.length === 1) {
      if (['checkbox'].includes(form_field.type)) {
        return (
          <Checkbox
            checked={entry_items[0].value.toLowerCase() === 'true'}
            disabled={true}
            inputProps={{ 'aria-label': entry_items[0].label }}
          />
        );
      }
      if (['datetime', 'reservation_date_time'].includes(form_field.type)) {
        return <>{moment(entry_items[0].value).format('YYYY-MM-DD HH:mm')}</>;
      }
      return <>{entry_items[0].value}</>;
    }
    return (
      <Box>
        {entry_items.map((entry_item, index) => (
          <Box key={index}>{entry_item.value}</Box>
        ))}
      </Box>
    );
  };

  const [alertModalState, setAlartModalState] = React.useState({
    open: false,
    message: null,
    okLabel: null,
    ngLabel: null,
    callback: null,
  });

  const handleOpenAlartModal = (message, okLabel, ngLabel, callback) => {
    setAlartModalState({
      open: true,
      message,
      okLabel,
      ngLabel,
      callback,
    });
  };

  const handleCloseAlartModal = async (result) => {
    if (result && alertModalState.callback) {
      await alertModalState.callback();
    }
    setAlartModalState({
      open: false,
      message: null,
      okLabel: null,
      ngLabel: null,
      callback: null,
    });
  };

  const handleAccept = async () => {
    const response = await fetch(`/api/v1/entries/${state.entry.id}/accept`, {
      method: 'POST',
    });
    if (response.status === 200) {
      setState({
        ...state,
        result: {
          messageType: 'success.main',
          message: '予約 OK を送信しました',
        },
      });
    } else {
      setState({
        ...state,
        result: {
          messageType: 'error.main',
          message: '予約 OK の送信が失敗しました',
        },
      });
    }
  };

  const handleCancel = async () => {
    const response = await fetch(`/api/v1/entries/${state.entry.id}/cancel`, {
      method: 'POST',
    });
    if (response.status === 200) {
      setState({
        ...state,
        result: {
          messageType: 'success.main',
          message: '予約 NG を送信しました',
        },
      });
    } else {
      setState({
        ...state,
        result: {
          messageType: 'error.main',
          message: '予約 NG の送信が失敗しました',
        },
      });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                予約詳細
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box align="right">
                {['show'].includes(props.mode) && (
                  <Fab color="primary" size="small" aria-label="edit" href={`/entries/${state.entry.id}/edit`}>
                    <EditIcon />
                  </Fab>
                )}
                {['new', 'edit'].includes(props.mode) && (
                  <form
                    id="form"
                    action={props.action}
                    method="post"
                    noValidate
                  >
                    <input type="hidden" name="authenticity_token" value={props.csrfToken} />
                    <input type="hidden" name="_method" value={props.method} />
                    <input type="hidden" name="entry[id]" value={state.entry.id || ''} />
                    {state.entry.entry_items.map((entry_item, i) => (
                      <div key={i}>
                        <input type="hidden" name={`entry[entry_items_attributes][${i}][id]`} value={entry_item.id || ''} />
                        <input type="hidden" name={`entry[entry_items_attributes][${i}][form_field_id]`} value={entry_item.form_field_id || ''} />
                        <input type="hidden" name={`entry[entry_items_attributes][${i}][name]`} value={entry_item.name || ''} />
                        <input type="hidden" name={`entry[entry_items_attributes][${i}][label]`} value={entry_item.label || ''} />
                        <input type="hidden" name={`entry[entry_items_attributes][${i}][value]`} value={entry_item.value || ''} />
                      </div>
                    ))}
                    <Fab color="secondary" size="small" aria-label="save" type="submit">
                      <SaveIcon />
                    </Fab>
                  </form>
              )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ErrorMessage {...props} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  disabled={true}
                  label="店舗名"
                  value={form.store.name || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  disabled={true}
                  label="フォーム名"
                  value={form.name || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  disabled={true}
                  label="外部Id"
                  value={entry.external_id || ''}
                />
              </FormControl>
            </Grid>
          </Grid>
          {form && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>データ名</TableCell>
                  <TableCell>ラベル名</TableCell>
                  <TableCell>値</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {form.form_fields.map((form_field) => (
                  <TableRow key={`${form_field.position}:${form_field.id}`}>
                    <TableCell>
                      {form_field.position}
                    </TableCell>
                    <TableCell>
                      {getEntryItemName(form_field)}
                    </TableCell>
                    <TableCell>
                      {getEntryItemLabel(form_field)}
                    </TableCell>
                    <TableCell>
                      <Box style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                        {getEntryItemValue(form_field)}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {['new', 'edit'].includes(props.mode) && (
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <IconButton color="default" size="small" aria-label="edit" onClick={(event) => handleToggleDrawer(event, form_field)}>
                            <EditIcon />
                          </IconButton>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {['show'].includes(props.mode) && (
            <Box align="right" mt={5}>
              {state.entry.accepted == null ? state.result?.messageType ? (
                <Box color={state.result.messageType} display="inline">
                  {state.result.message}
                </Box>
              ) : (
                <>
                  <Box display="inline" ml={1}>
                    <Button
                      color="primary"
                      mr={5}
                      startIcon={<SmsIcon />}
                      variant="contained"
                      onClick={() => handleOpenAlartModal(
                        '予約 OK でショートメールを送信します。よろしいですか？',
                        '送信',
                        'やめる',
                        handleAccept
                      )}
                    >
                      予約 OK
                    </Button>
                  </Box>
                  <Box display="inline" ml={1}>
                    <Button
                      color="secondary"
                      startIcon={<SmsIcon />}
                      variant="contained"
                      onClick={() => handleOpenAlartModal(
                        '予約 NG でショートメールを送信します。よろしいですか？',
                        '送信',
                        'やめる',
                        handleCancel
                      )}
                    >
                      予約 NG
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {state.entry.accepted ? (
                    <Chip
                    　color="primary"
                      disabled
                      icon={<DoneIcon />}
                      label="予約 OK"
                    />
                  ) : (
                    <Chip
                    　color="secondary"
                      disabled
                      icon={<DoneIcon />}
                      label="予約 NG"
                    />
                  )}
                </>
              )}
              {state.entry.last_notify_sms_log?.delivered_result_code == '0001' && (
                <Box color="success.main" mt={1}>
                  送信成功
                </Box>
              )}
              {state.entry.last_notify_sms_log?.delivered_result_code && state.entry.last_notify_sms_log?.delivered_result_code != '0001' && (
                <Box color="error.main" mt={1}>
                  送信エラー
                </Box>
              )}
              {state.entry.last_notify_sms_log?.responded_result_code && state.entry.last_notify_sms_log?.responded_result_code != '0000' && (
                <Box color="error.main" mt={1}>
                  送信エラー
                </Box>
              )}
            </Box>
          )}
          <Drawer anchor="right" open={!!state.drawer} onClose={(event) => handleToggleDrawer(event, null)}>
            <Box className={classes.drawer}>
              <Box mb={3}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography component="h1" variant="h5">
                      フィールド No.{state.drawer ? state.drawer.form_field.position : '--'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right">
                      <IconButton color="default" size="small" aria-label="close" onClick={(event) => handleToggleDrawer(event, null)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {state.drawer && (
                <Grid container spacing={1}>
                  {['name', 'address'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        fullWidth
                        id={entry_item.name}
                        label={entry_item.label}
                        margin="normal"
                        name={entry_item.name}
                        onChange={(event) => handleEntryItemChange(event)}
                        type="text"
                        value={entry_item.value}
                      />
                    </Grid>
                  ))}
                  {['email', 'tel', 'text'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        fullWidth
                        id={entry_item.name}
                        label={entry_item.label}
                        margin="normal"
                        name={entry_item.name}
                        onChange={(event) => handleEntryItemChange(event)}
                        type={state.drawer.form_field.type}
                        value={entry_item.value}
                      />
                    </Grid>
                  ))}
                  {['textarea'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        fullWidth
                        id={entry_item.name}
                        label={entry_item.label}
                        margin="normal"
                        multiline
                        name={entry_item.name}
                        onChange={(event) => handleEntryItemChange(event)}
                        rows={10}
                        value={entry_item.value}
                      />
                    </Grid>
                  ))}
                  {['date'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <MuiPickersUtilsProvider key={index} utils={MomentUtils}>
                      <KeyboardDatePicker
                        format="YYYY-MM-DD"
                        fullWidth
                        id={entry_item.name}
                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                        label={entry_item.label}
                        margin="normal"
                        onChange={(value) => handleEntryItemDateChange(entry_item.name, value)}
                        value={entry_item.value}
                        variant="inline"
                      />
                    </MuiPickersUtilsProvider>
                  ))}
                  {['datetime', 'reservation_date_time'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <MuiPickersUtilsProvider key={index} utils={MomentUtils}>
                      <KeyboardDatePicker
                        format="YYYY-MM-DD"
                        fullWidth
                        id={`${entry_item.name}-date`}
                        KeyboardButtonProps={{ 'aria-label': entry_item.label }}
                        label={entry_item.label}
                        margin="normal"
                        onChange={(value) => handleEntryItemDateTimeChange(entry_item.name, value)}
                        value={entry_item.value}
                        variant="inline"
                      />
                      <KeyboardTimePicker
                        format="HH:mm"
                        fullWidth
                        id={`${entry_item.name}-time`}
                        KeyboardButtonProps={{ 'aria-label': entry_item.label }}
                        label={entry_item.label}
                        margin="normal"
                        minutesStep={30}
                        onChange={(value) => handleEntryItemDateTimeChange(entry_item.name, value)}
                        value={entry_item.value}
                        variant="inline"
                      />
                    </MuiPickersUtilsProvider>
                  ))}
                  {['checkbox'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <FormControl
                      key={index}
                      component="fieldset"
                    >
                      <FormLabel component="legend">{entry_item.label}</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={entry_item.value.toLowerCase() === 'true'}
                              name={entry_item.name}
                              onChange={(event) => handleEntryItemCheckboxChange(event)}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  ))}
                  {['radio'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <FormControl
                      key={index}
                      component="fieldset"
                    >
                      <FormLabel component="legend">{entry_item.label}</FormLabel>
                      <RadioGroup
                        aria-label={entry_item.label}
                        name={entry_item.name}
                        onChange={(event) => handleEntryItemChange(event)}
                        value={entry_item.value}
                      >
                        {state.drawer.form_field.form_field_options.map((form_field_option, index) => (
                          <FormControlLabel
                            control={<Radio />}
                            key={index}
                            label={form_field_option.label}
                            value={form_field_option.value}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  ))}
                  {['select'].includes(state.drawer.form_field.type) && state.drawer.entry_items.map((entry_item, index) => (
                    <FormControl
                      key={index}
                      fullWidth
                      required
                    >
                      <InputLabel id={`${entry_item.name}_label`}>{entry_item.label}</InputLabel>
                      <Select
                        id={entry_item.name}
                        label={entry_item.label}
                        labelId={`${entry_item.name}_label`}
                        name={entry_item.name}
                        onChange={(event) => handleEntryItemChange(event)}
                        value={entry_item.value}
                      >
                        <MenuItem value=""><em>選択してください</em></MenuItem>
                        {state.drawer.form_field.form_field_options.map((form_field_option, index) => (
                          <MenuItem key={index} value={form_field_option.value}>{form_field_option.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))}
                </Grid>
              )}
            </Box>
          </Drawer>
          <Dialog
            open={alertModalState.open}
            onClose={() => handleCloseAlartModal(false)}
          >
            <DialogContent>
              <DialogContentText>
                {alertModalState.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseAlartModal(true)} color="primary">
                {alertModalState.okLabel}
              </Button>
              <Button onClick={() => handleCloseAlartModal(false)} color="primary" autoFocus>
                {alertModalState.ngLabel}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </React.Fragment>
  );
}
