// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
}));

export default function SignUp(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
    </React.Fragment>
  );
}