// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// custom components
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  drawer: {
    padding: 20,
    width: 400,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 400,
  },
}));

export default function Form(props) {
  const classes = useStyles();

  const stores = props.stores ? JSON.parse(props.stores) : [];
  const form = JSON.parse(props.form);
  form.form_fields = form.form_fields.sort((a, b) => a.position - b.position);
  form.form_fields.forEach((form_field) => {
    form_field.form_field_options = form_field.form_field_options.sort((a, b) => a.position - b.position);
  });

  const [state, setState] = React.useState({
    form,
    drawer: null,
  });

  const handleFormChange = (event) => {
    setState({
      ...state,
      form: {
        ...state.form,
        [event.target.name]: event.target.value
      },
    });
  };

  const handleFromTimeChange = (name, value) => {
    setState({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    });
  };

  const handleFormFieldChange = (event) => {
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        [event.target.name]: event.target.value
      },
    });
  };

  const handleFormFieldCheckboxChange = (event) => {
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        [event.target.name]: event.target.checked
      },
    });
  };

  const handleFormFieldOptionChange = (event, position) => {
    const form_field_options = [...state.drawer.form_field_options];
    if (!(form_field_options)) {
      return;
    }
    const target = form_field_options.find((form_field_option) => form_field_option.position === position);
    target[event.target.name] = event.target.value;
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        form_field_options,
      },
    });
  };

  const handleSwapFormFieldPosition = (position, value) => {
    const form_fields = [...state.form.form_fields];
    const source = form_fields.find((form_field) => form_field.position === position);
    const target = form_fields.find((form_field) => form_field.position === position + value);
    if (!(source || target)) {
      return;
    }
    source.position = position + value;
    target.position = position;
    setState({
      ...state,
      form: {
        ...state.form,
        form_fields: [...form_fields.sort((a, b) => a.position - b.position)],
      },
    });
  };

  const handleSwapFormFieldOptionPosition = (position, value) => {
    const form_field_options = [...state.drawer.form_field_options];
    const source = form_field_options.find((form_field_option) => form_field_option.position === position);
    const target = form_field_options.find((form_field_option) => form_field_option.position === position + value);
    if (!(source || target)) {
      return;
    }
    source.position = position + value;
    target.position = position;
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        form_field_options: [...form_field_options.sort((a, b) => a.position - b.position)],
      },
    });
  };

  const handleDeleteFormField = (position) => {
    const form_fields = state.form.form_fields.filter((form_field) => form_field.position !== position);
    form_fields.forEach((form_field, index) => {
      form_field.position = index + 1;
    });
    setState({
      ...state,
      form: {
        ...state.form,
        form_fields,
      },
    });
  };

  const handleDeleteFormFieldOption = (position) => {
    const form_field_options = state.drawer.form_field_options.filter((form_field_option) => form_field_option.position !== position);
    form_field_options.forEach((form_field_option, index) => {
      form_field_option.position = index + 1;
    });
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        form_field_options,
      },
    });
  };

  const handleAddFormField = () => {
    const form_field = {
      position: (state.form.form_fields ? state.form.form_fields.length : 0) + 1,
      type: '',
      name: '',
      label: '',
      required: 0,
      form_field_options: [],
    }
    setState({
      ...state,
      form: {
        ...state.form,
        form_fields: [...state.form.form_fields, form_field],
      },
    });
  };

  const handleAddFormFieldOption = () => {
    const form_field_option = {
      position: (state.drawer.form_field_options ? state.drawer.form_field_options.length : 0) + 1,
      name: '',
      value: '',
    }
    setState({
      ...state,
      drawer: {
        ...state.drawer,
        form_field_options: [...state.drawer.form_field_options, form_field_option],
      },
    });
  };

  const handleToggleDrawer = (event, data) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (data) {
      setState({
        ...state,
        drawer: data,
      });
      return;
    }
    if (state.drawer) {
      const form_fields = [...state.form.form_fields];
      const target = form_fields.find((form_field) => form_field.position === state.drawer.position);
      if (!(target)) {
        return;
      }
      target.type = state.drawer.type;
      target.name = state.drawer.name;
      target.label = state.drawer.label;
      target.required = state.drawer.required;
      target.form_field_options = [...state.drawer.form_field_options];
      setState({
        ...state,
        drawer: data,
        form: {
          ...state.form,
          form_fields,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography component="h1" variant="h5">
                フォーム詳細
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box align="right">
                {['show'].includes(props.mode) && (
                  <Fab color="primary" size="small" aria-label="edit" href={`/forms/${state.form.id}/edit`}>
                    <EditIcon />
                  </Fab>
                )}
                {['new', 'edit'].includes(props.mode) && (
                  <form
                    id="form"
                    action={props.action}
                    method="post"
                    noValidate
                  >
                    <input type="hidden" name="authenticity_token" value={props.csrfToken} />
                    <input type="hidden" name="_method" value={props.method} />
                    <input type="hidden" name="form[id]" value={state.form.id || ''} />
                    <input type="hidden" name="form[store_id]" value={state.form.store_id || ''} />
                    <input type="hidden" name="form[name]" value={state.form.name || ''} />
                    <input type="hidden" name="form[redirect_url]" value={state.form.redirect_url || ''} />
                    {state.form.form_fields.map((form_field, i) => (
                      <div key={i}>
                        <input type="hidden" name={`form[form_fields_attributes][${i}][id]`} value={form_field.id || ''} />
                        <input type="hidden" name={`form[form_fields_attributes][${i}][position]`} value={form_field.position || ''} />
                        <input type="hidden" name={`form[form_fields_attributes][${i}][type]`} value={form_field.type || ''} />
                        <input type="hidden" name={`form[form_fields_attributes][${i}][name]`} value={form_field.name || ''} />
                        <input type="hidden" name={`form[form_fields_attributes][${i}][label]`} value={form_field.label || ''} />
                        <input type="hidden" name={`form[form_fields_attributes][${i}][required]`} value={form_field.required || 0} />
                        {form_field.form_field_options.map((form_field_option, j) => (
                          <div key={j}>
                            <input type="hidden" name={`form[form_fields_attributes][${i}][form_field_options_attributes][${j}][id]`} value={form_field_option.id || ''} />
                            <input type="hidden" name={`form[form_fields_attributes][${i}][form_field_options_attributes][${j}][position]`} value={form_field_option.position || ''} />
                            <input type="hidden" name={`form[form_fields_attributes][${i}][form_field_options_attributes][${j}][label]`} value={form_field_option.label || ''} />
                            <input type="hidden" name={`form[form_fields_attributes][${i}][form_field_options_attributes][${j}][value]`} value={form_field_option.value || ''} />
                          </div>
                        ))}
                      </div>
                    ))}
                    <Fab color="secondary" size="small" aria-label="save" type="submit">
                      <SaveIcon />
                    </Fab>
                  </form>
              )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ErrorMessage {...props} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
                required={['new', 'edit'].includes(props.mode)}
              >
                <InputLabel id="store_id_label">店舗</InputLabel>
                <Select
                  disabled={['show'].includes(props.mode)}
                  id="store_id"
                  label="店舗"
                  labelId="store_id_label"
                  name="store_id"
                  onChange={(event) => handleFormChange(event)}
                  value={state.form.store_id || ''}
                >
                  <MenuItem value=""><em>選択してください</em></MenuItem>
                  {stores.map((store, index) => (
                    <MenuItem key={index} value={store.id}>{store.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="name"
                  label="フォーム名"
                  name="name"
                  onChange={(event) => handleFormChange(event)}
                  required={['new', 'edit'].includes(props.mode)}
                  type="text"
                  value={state.form.name || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  disabled={true}
                  id="store_page_url"
                  label="店舗ページ URL"
                  name="store_page_url"
                  type="text"
                  value={state.form.store_page_url || ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  disabled={['show'].includes(props.mode)}
                  id="redirect_url"
                  label="リダイレクト URL"
                  name="redirect_url"
                  onChange={(event) => handleFormChange(event)}
                  type="text"
                  value={state.form.redirect_url || ''}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>データ型</TableCell>
                <TableCell>データ名</TableCell>
                <TableCell>ラベル名</TableCell>
                <TableCell>必須</TableCell>
                <TableCell>オプション</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.form.form_fields.map((form_field) => (
                <TableRow key={`${form_field.position}:${form_field.id}`}>
                  <TableCell>{form_field.position}</TableCell>
                  <TableCell>{form_field.type}</TableCell>
                  <TableCell>{form_field.name}</TableCell>
                  <TableCell>{form_field.label}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={form_field.required}
                      disabled
                      inputProps={{ 'aria-label': 'required' }}
                    />
                  </TableCell>
                  <TableCell>
                    {form_field.form_field_options.map((form_field_option) => (
                      <Box key={`${form_field_option.position}:${form_field_option.id}`}>{form_field_option.position}. {form_field_option.label} [{form_field_option.value}]</Box>
                    ))}
                  </TableCell>
                  <TableCell align="right">
                    {['new', 'edit'].includes(props.mode) && (
                      <Box style={{ whiteSpace: 'nowrap' }}>
                        <IconButton color="default" size="small" aria-label="up" onClick={() => handleSwapFormFieldPosition(form_field.position, -1)}>
                          <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton color="default" size="small" aria-label="down" onClick={() => handleSwapFormFieldPosition(form_field.position, 1)}>
                          <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton color="default" size="small" aria-label="edit" onClick={(event) => handleToggleDrawer(event, form_field)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="secondary" size="small" aria-label="delete" onClick={() => handleDeleteFormField(form_field.position)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="right" colSpan={7} style={{ borderBottom: 'none' }}>
                  {['new', 'edit'].includes(props.mode) && (
                    <IconButton color="default" size="small" aria-label="add" onClick={() => handleAddFormField()}>
                      <AddIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <Drawer anchor="right" open={!!state.drawer} onClose={(event) => handleToggleDrawer(event, null)}>
            <Box className={classes.drawer}>
              <Box mb={3}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography component="h1" variant="h5">
                      フィールド No.{state.drawer ? state.drawer.position : '--'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right">
                      <IconButton color="default" size="small" aria-label="close" onClick={(event) => handleToggleDrawer(event, null)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    required
                    variant="outlined"
                  >
                    <InputLabel id="type_label">データ型</InputLabel>
                    <Select
                      id="type"
                      label="データ型"
                      labelId="type_label"
                      name="type"
                      onChange={(event) => handleFormFieldChange(event)}
                      value={state.drawer ? state.drawer.type : ''}
                    >
                      <MenuItem value=""><em>選択してください</em></MenuItem>
                      <MenuItem value="name">name</MenuItem>
                      <MenuItem value="email">email</MenuItem>
                      <MenuItem value="address">address</MenuItem>
                      <MenuItem value="tel">tel</MenuItem>
                      <MenuItem value="text">text</MenuItem>
                      <MenuItem value="textarea">textarea</MenuItem>
                      <MenuItem value="date">date</MenuItem>
                      <MenuItem value="datetime">datetime</MenuItem>
                      <MenuItem value="reservation_date_time">reservation date time</MenuItem>
                      <MenuItem value="checkbox">checkbox</MenuItem>
                      {/* <MenuItem value="radio">radio</MenuItem> */}
                      <MenuItem value="select">select</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="データ名"
                    margin="normal"
                    name="name"
                    onChange={(event) => handleFormFieldChange(event)}
                    required
                    type="text"
                    value={state.drawer ? state.drawer.name : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="label"
                    label="ラベル名"
                    margin="normal"
                    name="label"
                    onChange={(event) => handleFormFieldChange(event)}
                    required
                    type="text"
                    value={state.drawer ? state.drawer.label : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.drawer ? state.drawer.required : false}
                        name="required"
                        onChange={(event) => handleFormFieldCheckboxChange(event)}
                      />
                    }
                    label="必須"
                  />
                </Grid>
                {state.drawer && ['radio', 'select'].includes(state.drawer.type) && state.drawer.form_field_options.map((form_field_option) => (
                  <Grid item xs={12} key={`${form_field_option.position}:${form_field_option.id}`}>
                    <Paper>
                      <Box m={1}>
                        <Grid container>
                          <Grid item xs={8} style={{ alignSelf: 'center' }}>
                            オプション No.{form_field_option.position}
                          </Grid>
                          <Grid item xs={4}>
                            <Box textAlign="right">
                              <IconButton color="default" size="small" aria-label="up" onClick={() => handleSwapFormFieldOptionPosition(form_field_option.position, -1)}>
                                <ArrowUpwardIcon />
                              </IconButton>
                              <IconButton color="default" size="small" aria-label="down" onClick={() => handleSwapFormFieldOptionPosition(form_field_option.position, 1)}>
                                <ArrowDownwardIcon />
                              </IconButton>
                              <IconButton color="secondary" size="small" aria-label="delete" onClick={() => handleDeleteFormFieldOption(form_field_option.position)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                        <TextField
                          fullWidth
                          id={`label${form_field_option.position}`}
                          label="ラベル名"
                          margin="normal"
                          name="label"
                          onChange={(event) => handleFormFieldOptionChange(event, form_field_option.position)}
                          required
                          type="text"
                          value={form_field_option.label}
                          variant="outlined"
                        />
                        <TextField
                          fullWidth
                          id={`value${form_field_option.position}`}
                          label="データ値"
                          margin="normal"
                          name="value"
                          onChange={(event) => handleFormFieldOptionChange(event, form_field_option.position)}
                          required
                          type="text"
                          value={form_field_option.value}
                          variant="outlined"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                ))}
                {state.drawer && ['radio', 'select'].includes(state.drawer.type) && (
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2}>
                      <Box textAlign="right">
                        <IconButton color="default" size="small" aria-label="add" onClick={() => handleAddFormFieldOption()}>
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Drawer>
        </Box>
      </Container>
    </React.Fragment>
  );
}
