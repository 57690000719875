// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import AccountCircle from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import StorefrontIcon from '@material-ui/icons/Storefront';
import WebIcon from '@material-ui/icons/Web';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

export default function NavigationBar(props) {
  const classes = useStyles();
  const [menu, setMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenu(open);
  };

  return (
    <Box className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            予約ドーン管理システム
          </Typography>
          <Box>
            <IconButton
              aria-label="account of current user"
              aria-controls="navigation-bar-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="navigation-bar-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
            {props.isSignedIn ? (
              <Box>
                <MenuItem button component="a" href="/company">
                  <ListItemIcon>
                    <BusinessIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="会社情報" />
                </MenuItem>
                <MenuItem button component="a" href="/users/edit">
                  <ListItemIcon>
                    <PersonOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="アカウント情報" />
                </MenuItem>
                <MenuItem button component="a" href="/users/sign_out" data-method="delete">
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="ログアウト" />
                </MenuItem>
              </Box>
            ) : (
              <Box>
                <MenuItem button component="a" href="/users/sign_in">
                  <ListItemIcon>
                    <InputIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="ログイン" />
                </MenuItem>
              </Box>
            )}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={menu} onClose={toggleDrawer(false)}>
        <Box
          className={classes.list}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          role="presentation"
        >
          <List>
            <ListItem button component="a" href="/stores">
              <ListItemIcon><StorefrontIcon /></ListItemIcon>
              <ListItemText primary="店舗情報一覧" />
            </ListItem>
            <ListItem button component="a" href="/forms">
              <ListItemIcon><WebIcon /></ListItemIcon>
              <ListItemText primary="フォーム一覧" />
            </ListItem>
            <ListItem button component="a" href="/entries">
              <ListItemIcon><EventNoteIcon /></ListItemIcon>
              <ListItemText primary="予約一覧" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
