// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import DoneIcon from '@material-ui/icons/Done';

// material-ui/pickers components
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// date-io components
import MomentUtils from '@date-io/moment';

// moment components
import moment from 'moment';

// fetch components
import fetch from 'node-fetch';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
  ;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Entries(props) {
  const classes = useStyles();

  const [entries, setEntries] = React.useState(JSON.parse(props.entries));

  const [reservationDateFrom, setReservationDateFrom] = React.useState(moment().startOf('day'));
  const [reservationDateTo, setReservationDateTo] = React.useState(moment().startOf('day').add(30, 'day'));
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('reservation_date_time');
  const [page, setPage] = React.useState(0);

  const rowsPerPage = 20;

  const rows = entries.filter((entry) => {
    if (!(entry.reservation_date_time && reservationDateFrom && reservationDateTo)) {
      return false;
    }
    const date = moment(entry.reservation_date_time);
    return date >= reservationDateFrom?.startOf('day') && date <= reservationDateTo?.endOf('day');
  });

  const handleRequestSort = (event, property) => {
    setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [alertModalState, setAlartModalState] = React.useState({
    open: false,
    entryId: null,
    message: null,
    okLabel: null,
    ngLabel: null,
    callback: null,
  });

  const handleOpenAlartModal = (entryId, message, okLabel, ngLabel, callback) => {
    setAlartModalState({
      open: true,
      entryId,
      message,
      okLabel,
      ngLabel,
      callback,
    });
  };

  const handleCloseAlartModal = async (result) => {
    if (result && alertModalState.callback) {
      await alertModalState.callback(alertModalState);
    }
    setAlartModalState({
      open: false,
      entryId: null,
      message: null,
      okLabel: null,
      ngLabel: null,
      callback: null,
    });
  };

  const handleAccept = async (state) => {
    const entry = entries.find((entry) => entry.id === state.entryId);
    if (!entry) {
      return;
    }
    const response = await fetch(`/api/v1/entries/${entry.id}/accept`, {
      method: 'POST',
    });
    if (response.status === 200) {
      entry.result = {
        messageType: 'success.main',
        message: '予約 OK を送信しました',
      };
    } else {
      entry.result = {
        messageType: 'error.main',
        message: '予約 OK の送信が失敗しました',
      };
    }
    setEntries([...entries]);
  };

  const handleCancel = async (state) => {
    const entry = entries.find((entry) => entry.id === state.entryId);
    if (!entry) {
      return;
    }
    const response = await fetch(`/api/v1/entries/${entry.id}/cancel`, {
      method: 'POST',
    });
    if (response.status === 200) {
      entry.result = {
        messageType: 'success.main',
        message: '予約 NG を送信しました',
      };
    } else {
      entry.result = {
        messageType: 'error.main',
        message: '予約 NG の送信が失敗しました',
      };
    }
    setEntries([...entries]);
  };

  const handleEntryCancel = async (state) => {
    const entry = entries.find((entry) => entry.id === state.entryId);
    if (!entry) {
      return;
    }
    const response = await fetch(`/api/v1/entries/${entry.id}/cancel_entry`, {
      method: 'POST',
    });
    if (response.status === 200) {
      entry.is_canceled = true;
    } else {
      entry.cancel_result = {
        messageType: 'error.main',
        message: '予約のキャンセルに失敗しました',
      };
    }
    setEntries([...entries]);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg">
        <Box className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography component="h1" variant="h5">
                予約一覧
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="right">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    format="YYYY-MM-DD"
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    label="From"
                    maxDate={reservationDateTo}
                    size="small"
                    value={reservationDateFrom}
                    variant="inline"
                    onChange={(date) => setReservationDateFrom(date)}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    format="YYYY-MM-DD"
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    label="To"
                    minDate={reservationDateFrom}
                    size="small"
                    value={reservationDateTo}
                    variant="inline"
                    onChange={(date) => setReservationDateTo(date)}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
          </Grid>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {[
                    { id: 'store_name', label: '店舗名' },
                    { id: 'id', label: '予約番号' },
                    { id: 'reservation_date_time', label: '予約日時' },
                    { id: 'name', label: '氏名' },
                    { id: 'tel', label: '電話番号' },
                  ].map((cell) => (
                    <TableCell
                      align="center"
                      key={cell.id}
                      sortDirection={orderBy === cell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === cell.id}
                        direction={orderBy === cell.id ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, cell.id)}
                      >
                        {cell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align="center">ステータス</TableCell>
                  <TableCell align="center">予約キャンセル</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((entry, index) => (
                  <TableRow key={entry.id}>
                    <TableCell align="center">{entry.store_name}</TableCell>
                    <TableCell align="center"><Link href={`/entries/${entry.id}`}>{entry.id}</Link></TableCell>
                    <TableCell align="center">{moment(entry.reservation_date_time).format('YYYY-MM-DD HH:mm')}</TableCell>
                    <TableCell align="center">{entry.name}</TableCell>
                    <TableCell align="center">{entry.tel}</TableCell>
                    <TableCell align="center">
                      {entry.accepted == null ? entry.result?.messageType ? (
                        <Box color={entry.result.messageType}>
                          {entry.result.message}
                        </Box>
                      ) : (
                        <Box>
                          <Box display="inline" ml={1}>
                            <Button
                              color="primary"
                              mr={5}
                              variant="contained"
                              onClick={() => handleOpenAlartModal(
                                entry.id,
                                '予約 OK でショートメールを送信します。よろしいですか？',
                                '送信',
                                'やめる',
                                handleAccept
                              )}
                            >
                              予約 OK
                            </Button>
                          </Box>
                          <Box display="inline" ml={1}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => handleOpenAlartModal(
                                entry.id,
                                '予約 NG でショートメールを送信します。よろしいですか？',
                                '送信',
                                'やめる',
                                handleCancel
                              )}
                            >
                              予約 NG
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          {entry.accepted ? (
                            <Chip
                              color="primary"
                              disabled
                              icon={<DoneIcon />}
                              label="予約 OK"
                            />
                          ) : (
                            <Chip
                              color="secondary"
                              disabled
                              icon={<DoneIcon />}
                              label="予約 NG"
                            />
                          )}
                        </Box>
                      )}
                      {entry.last_notify_sms_log?.delivered_result_code == '0001' && (
                        <Box color="success.main" mt={1}>
                          送信成功
                        </Box>
                      )}
                      {entry.last_notify_sms_log?.delivered_result_code && entry.last_notify_sms_log?.delivered_result_code != '0001' && (
                        <Box color="error.main" mt={1}>
                          送信エラー
                        </Box>
                      )}
                      {entry.last_notify_sms_log?.responded_result_code && entry.last_notify_sms_log?.responded_result_code != '0000' && (
                        <Box color="error.main" mt={1}>
                          送信エラー
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {(entry.is_canceled == false && entry.accepted == true) && (
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => handleOpenAlartModal(
                              entry.id,
                              '予約をキャンセルします。よろしいですか？',
                              '予約をキャンセル',
                              'やめる',
                              handleEntryCancel
                            )}
                          >
                            キャンセル
                          </Button>
                        </Box>
                      )}
                      {(entry.is_canceled == true && entry.accepted == true) && (
                        <Box>
                          <Chip
                            color="secondary"
                            disabled
                            icon={<DoneIcon />}
                            label="キャンセル済"
                          />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            onChangePage={(event, newPage) => setPage(newPage)}
          />
        </Box>
        <Dialog
          open={alertModalState.open}
          onClose={() => handleCloseAlartModal(false)}
        >
          <DialogContent>
            <DialogContentText>
              {alertModalState.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseAlartModal(true)} color="primary">
              {alertModalState.okLabel}
            </Button>
            <Button onClick={() => handleCloseAlartModal(false)} color="primary" autoFocus>
              {alertModalState.ngLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </React.Fragment>
  );
}
