// react components
import React from 'react';

// material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// material-ui/icons components
import InputIcon from '@material-ui/icons/Input';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

// custom components
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box className={classes.root}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <ErrorMessage {...props} />
          <form
            action="/users/sign_in"
            className={classes.form}
            method="post"
            noValidate
          >
            <input type="hidden" name="authenticity_token" value={props.csrfToken} />
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[email]"
                  autoFocus
                  fullWidth
                  id="user[email]"
                  label="メールアドレス"
                  margin="normal"
                  name="user[email]"
                  required
                  type="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user[password]"
                  fullWidth
                  id="user[password]"
                  label="パスワード"
                  margin="normal"
                  name="user[password]"
                  required
                  type="password"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              className={classes.submit}
              color="primary"
              fullWidth
              size="large"
              startIcon={<InputIcon />}
              type="submit"
              variant="contained"
            >
              ログイン
            </Button>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
}
